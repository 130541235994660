const version = '5';

type SearchCachedResponseCallback = (request: Request, cachedReponse: ResponseInit, json: Array<Record<string, any>>) => void;

function init() {
    return caches.open(version).then((cache) => {
        return cache.addAll([
            '/images/loading-icon.png',
            '/images/loading-icon.svg',
            '/images/via-logo.png',
            '/images/via-logo.svg',
            '/images/icons/online.svg',
            '/images/icons/offline.svg',
            '/models/compass-windrose.glb',
            '/models/compass-windrose.glb.manifest',
        ]);
    });
}

function activate() {
    return caches.keys().then((keys) => {
        return Promise.all(
            keys.filter((key) => key !== version)
                .map((key) => caches.delete(key))
        );
    });
}

function cache() {
    return caches.open(version);
}

const match = (request: Request, options: CacheQueryOptions | undefined = {
    ignoreSearch: false,
    ignoreMethod: false,
    ignoreVary: false,
}) => cache().then((cache) => cache.match(request, options));

const keys = (request: Request, options: CacheQueryOptions | undefined = {
    ignoreSearch: false,
    ignoreMethod: false,
    ignoreVary: false,
}) => cache().then((cache) => cache.keys(request, options));

const put = (request: Request, response: Response) => cache().then(
    (cache) => cache.put(request, response)
);

const featureRegex = /^\/api\/inspections\/(\d+)\/features\/([^/]*)(?:\/)?$/;
const inspectionDetailRegex = /^\/api\/inspections\/(\d+)\/inspection-details(?:(?:\/)([^/]*)?(?:\/)?)?$/;

const updateOfflineCachedRequest = (request: Request, response: Response, memoryId: string) => {
    const { url: urlString } = request;

    const url = new URL(urlString);
    const apiBaseUrl = new URL(url);
    const searchOptions = { ignoreSearch: true, ignoreVary: true };


    const updateCachedResponse = (requestKey: Request, cachedReponse: ResponseInit, newData: any) => {
        const blob = new Blob([JSON.stringify(newData)], { type : 'application/json' });
        const updatedResponse = new Response(blob, { status: cachedReponse.status, statusText: cachedReponse.statusText, headers: cachedReponse.headers });
        return put(requestKey, updatedResponse);
    };

    const searchCachedResponse = (requestSearch: Request, callback: SearchCachedResponseCallback) => {
        return keys(requestSearch, searchOptions).then((keys) => {
            const promiseList = new Array<Promise<any>>();
            keys.forEach((requestKey) => promiseList.push(
                match(requestKey).then((cachedReponse) => {
                    if (cachedReponse) {
                        return cachedReponse.json().then((json) => {
                            return callback(requestKey, cachedReponse, json);
                        });
                    }
                })
            ));

            return Promise.all(promiseList).then(() => response);
        });
    };

    // feature offline update
    let regexMatch = url.pathname.match(featureRegex);
    if (regexMatch) {
        const inspectionId = regexMatch[1];
        const feature = regexMatch[2];

        apiBaseUrl.pathname = `api/inspections/${inspectionId}/features`;

        const requestSearch = new Request(apiBaseUrl);

        const callback = (requestKey: Request, cachedReponse: ResponseInit, json: Array<Record<string, any>>) => {
            const index =  json.findIndex((_) => _.name === feature);
            if (index !== -1) {
                return response.clone().json().then((newJson) => {
                    json[index] = JSON.parse(newJson.body);
                    return updateCachedResponse(requestKey, cachedReponse, json);
                });
            }
        };

        return searchCachedResponse(requestSearch, callback);
    }

    // inspection detail offline update
    regexMatch = url.pathname.match(inspectionDetailRegex);
    if (regexMatch) {
        const inspectionId = regexMatch[1];
        const id = regexMatch[2];
        const hasId = !!id;
        const findingId = hasId ? parseInt(id, 10) : null;

        apiBaseUrl.pathname = `api/inspections/${inspectionId}/inspection-details`;

        const requestSearch = new Request(apiBaseUrl);

        const callback = hasId
            ? (requestKey: Request, cachedReponse: ResponseInit, json: Array<Record<string, any>>) => {
                const index =  json.findIndex((_) => _.id === findingId);
                if (index !== -1) {
                    return response.clone().json().then((newJson) => {
                        json[index] = JSON.parse(newJson.body);
                        return updateCachedResponse(requestKey, cachedReponse, json);
                    });
                }
            }
            : (requestKey: Request, cachedReponse: ResponseInit, json: Array<Record<string, any>>) => {
                const index =  json.findIndex((_) => _._memoryId === memoryId);
                return response.clone().json().then((newJson) => {
                    const newObj = JSON.parse(newJson.body);
                    newObj._memoryId = memoryId;

                    if (index !== -1) {
                        json[index] = newObj;
                    } else {
                        json.push(newObj);
                    }

                    return updateCachedResponse(requestKey, cachedReponse, json);
                });
            };

        return searchCachedResponse(requestSearch, callback);
    }

    return response;
};

export { init, activate, match, cache, put, updateOfflineCachedRequest };